import React from "react"
import Advise from "../components/advise"
import SEO from "../components/seo"
import "../css/style.css"

const AvisoPage = () => (
  <>
    <div className="upcase">
      <SEO title="Inicio" />
      <Advise />
    </div>
  </>
)

export default AvisoPage
