import React from "react"
import "../css/style.css"

export default function Advise() {
  return (
    <section className="advise-section">
      <div>
        <p className="advise-title">AVISO DE PRIVACIDAD</p>
        <p className="advise-text">
          RBL Ingeniería, con domicilio en Santa Mónica 214, General Vicente
          Villada 57710 Nezahualcóyotl, Méx., es el responsable del tratamiento
          de los datos personales que nos proporcione, los cuales serán
          protegidos conforme a lo dispuesto por la Ley General de Protección de
          Datos Personales en Posesión de Sujetos Obligados, los Lineamientos
          Generales de Protección de Datos Personales para el Sector Público y
          demás normativa aplicable.
        </p>
        <p className="advise-subtitle">
          ¿Qué datos personales se recaban y para qué finalidad?
        </p>
        <p className="advise-text">
          Los datos personales que se recaben en el formulario tales como:
          <br />* Nombre
          <br />* Correo Electronico
          <br />* Telefono
          <br />
          <br /> se utilizarán con caracter responsable unicamente por el
          personal de contacto de la emrpesa.
        </p>
        <p className="advise-text">
          De manera adicional, utilizaremos su información personal para
          ponernos en contacto con usted y brindarle una mejor atención.
        </p>
        <p className="advise-text">
          Se informa que no se solicitarán datos personales sensibles.
        </p>
        <p className="advise-subtitle">
          Fundamento para el tratamiento de datos personales
        </p>
        <p className="advise-text">
          El tratamiento de sus datos personales se realiza con fundamento en
          los artículos 17, primer párrafo, y 21, fracción XXIV, de la Ley
          Federal de Transparencia y Acceso a la Información Pública, publicada
          en Diario Oficial de la Federación (DOF) 9 de mayo de 2016; 89,
          fracciones XXIV y XXVI de la Ley General de Protección de Datos
          Personales en Posesión de Sujetos Obligados publicada en el DOF el 26
          de enero de 2017 y 38 de la Ley Federal de Protección de Datos
          Personales en Posesión de los Particulares publicada en el DOF el 05
          de julio de 2010.
        </p>
        <p className="advise-subtitle">Transferencia de datos personales</p>
        <p className="advise-text">
          Se informa que no se realizarán transferencias de datos personales,
          salvo aquéllas que sean legalmente requeridas para la investigación y
          persecución de los delitos, así como la procuración o administración
          de justicia, pero también cuando la trasferencia sea precisa para el
          reconocimiento, ejercicio o defesa de un derecho ante autoridad
          competente, siempre y cuando medie el requerimiento respectivo. De
          conformidad con el artículo 70, fracciones III y IV, de la Ley General
          de Protección de Datos Personales en Posesión de Sujetos Obligados.
        </p>
        <p className="advise-subtitle">Elaboración: 18 de febrero de 2021</p>
      </div>
    </section>
  )
}
